import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';
import _includes from 'lodash/includes';

export const initialState = {
  locals: [],
  promotion: {},
  sortSelected: 'local-asc',
};

export const actionTypes = {
  LOAD_DATA_INIT: 'PROMOTION_LOCALS_LIST/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'PROMOTION_LOCALS_LIST/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'PROMOTION_LOCALS_LIST/LOAD_DATA_ERROR',
  CHANGE_SORT_OPTION: 'PROMOTION_LOCALS_LIST/CHANGE_SORT_OPTION',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS:
      return {
        ...state,
        locals: GraphQLTransforms.getNode(action.data[0], 'locals').map((el) => ({
          ...el,
          price: Number(el.price),
          priceM2: Number(el.priceM2),
          pricePromo: Number(el.pricePromo),
          priceM2Promo: Number(el.priceM2Promo),
          promoLowestPrice: Number(el.promoLowestPrice),
          status: el.status.entity.name,
          surface: Number(el.surface),
          type: el.type.entity,
          direction: el.direction.map((value) => (value.entity.name)),
          roomsList: el.roomsList.map((data) => {
            const entity = data.entity;
            return {
              id: entity.id,
              surface: Number(entity.surface),
              name: entity.name.value,
            };
          }),
          imageUrl: GraphQLTransforms.url(el.imageUrl ? el.imageUrl.entity : null),
        })),
        promotion: GraphQLTransforms.getNode(action.data[0], 'promotion')[0],
      };
    case actionTypes.CHANGE_SORT_OPTION: {
      const { sortSelected } = state;
      const selected = action.data;
      let data;

      if (_includes(sortSelected, `${selected.id}-desc`)) {
        data = `${selected.id}-asc`;
      } else {
        data = `${selected.id}-desc`;
      }

      return {
        ...state,
        sortSelected: data,
      };
    }
    default:
      return state;
  }
};

export const changeSortOption = (data) => ({
  type: actionTypes.CHANGE_SORT_OPTION,
  data,
});

export const loadData = (promotionId) => (dispatch) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const requests = {
    type: 'graphql',
    query: `
      query getPromotionLocals($id: String!) {
        promotion: nodeQuery(
          filter: {
            conditions: [
              {field: "type", value: ["special_offer"]},
              {field: "status", value: ["1"]},
              {field: "field_special_offer_promo_id", value: [$id]}
            ]
          }, 
          sort: [
            {field: "created", direction: DESC}
          ]
        ) {
          entities {
            ... on NodeSpecialOffer {
              id: nid,
              header: title,
              description: body {
                value
              },
              startDate: fieldSpecialOfferDates {
                value,
              },
              endDate: fieldSpecialOfferDates {
                endValue,
              },
              image: fieldSpecialOfferImage {
                derivative(style: NEWSTEASER) {
                  url,
                }
              },
              investment: fieldSpecialOfferInvestment {
                value: entity {
                  id: nid,
                  title,
                }
              }
            }
          }
        }
      
        locals: nodeQuery(
          limit: 1000, 
          filter: {
            conditions: [
              {field: "field_local_promo_id", value: [ $id ]},
              {field: "type", value: ["local"]},
              {field: "status", value: ["1"]},
              {field: "field_local_building.entity.status", value: ["1"]},
              {field: "field_local_building.entity.field_building_investment.entity.field_investment_active", value: ["1"]},
              # {field: "field_local_building.entity.field_building_hide_the_sale", value: ["0"]},
              {field: "field_local_status.entity.tid", value: ["1", "3", "4", "5"]},
            ]
          }
        ) {
          entities {
            ... on NodeLocal {
              id: nid,
              title,
              surface: fieldLocalSurface,
              price: fieldLocalTotalPrice,
              priceM2: fieldLocalPriceM2,
              pricePromo: fieldLocalTotalPricePromo,
              priceM2Promo: fieldLocalPriceM2Promo,
              promoLowestPrice: fieldLowestPromoPrice,
              status: fieldLocalStatus {
                entity {
                  id: tid,
                  name
                }
              },
              type: fieldLocalType {
                entity {
                  id: tid,
                  name
                }
              },
              direction: fieldLocalWorldDirection {
                entity {
                  id: tid,
                  name
                }
              },
              localNumber: fieldLocalNumber,
              imageUrl: fieldLocal2d {
                entity {
                  url
                }
              },
              isLocalCommercial: fieldIsCommercialLocal,
              rooms: fieldLocalRooms,
              roomsList: fieldLocalRoomsList {
                entity {
                  ... on ParagraphRoom {
                    id,
                    name: fieldRoomName {
                      value
                    },
                    surface: fieldRoomSurface,
                  }
                }
              },
              floor: fieldLocalFloor,
              features: fieldLocalFeatures {
                entity {
                  ... on TaxonomyTermLocalFeatures {
                    id: tid,
                    name,
                    img: fieldLocalFeatureIcon {
                      entity {
                        url
                      },
                    },
                  }
                }
              },
              investment: fieldLocalBuilding {
                entity {
                  ... on NodeBuilding {
                    id: nid,
                    buildingNumber: fieldBuildingNumber,
                    fieldBuildingFinishDate {
                      value,
                    },
                    fieldBuildingInvestment {
                      entity {
                        id: nid,
                        name: title,
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    variables: { id: promotionId },
  };

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
