import ApiManager from 'utils/ApiManager';
import * as GraphQLTransforms from 'utils/GraphQLTransforms';

export const initialState = {
  post: {},
  entities: [],
  slides: [],
};

export const actionTypes = {
  LOAD_DATA_INIT: 'BLOG_POST/LOAD_DATA_INIT',
  LOAD_DATA_SUCCESS: 'BLOG_POST/LOAD_DATA_SUCCESS',
  LOAD_DATA_ERROR: 'BLOG_POST/LOAD_DATA_ERROR',
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_DATA_SUCCESS: {
      const data = action.data[0].nodeById;

      return {
        ...state,
        entities: GraphQLTransforms.getNode(action.data[1], 'seeMore').map((el) => ({
          ...el,
          content: GraphQLTransforms.value(el.content),
          timestamp: el.timestamp * 1000,
          srcImage: GraphQLTransforms.imageStyle(el.srcImage),
        })),
        post: {
          ...data,
          content: GraphQLTransforms.value(data.content),
          timestamp: data.timestamp * 1000,
          srcImage: GraphQLTransforms.imageStyle(data.srcImage),
        },
        slides: GraphQLTransforms.getNode(action.data[1], 'slides').map((el) => ({
          ...el,
          imageUrl: GraphQLTransforms.imageStyle(el.imageUrl),
        })),
      };
    }
    default:
      return state;
  }
};

export const loadData = (blogSlug) => (dispatch, getStore) => {
  const actions = {
    onInit: actionTypes.LOAD_DATA_INIT,
    onSuccess: actionTypes.LOAD_DATA_SUCCESS,
    onError: actionTypes.LOAD_DATA_ERROR,
  };

  const store = getStore();
  const blogPostId = (store.Shared.mappedBlogPostsSlugs[blogSlug] || blogSlug).toString();

  const requests = [];
  requests.push({
    type: 'graphql',
    query: `
      query getBlogPost($id: String!) {
        nodeById(id: $id) {
          ... on NodeBlog {
            id: nid,
            slug: fieldBlogSlug,
            title,
            content: fieldBlogContent {
              value
            },
            timestamp: created,
            srcImage: fieldBlogPostPhoto {
              derivative(style: BLOGLARGE) {
                url,
              }
            }
          }
        }
      }
    `,
    variables: { id: blogPostId },
  });

  requests.push({
    type: 'graphql',
    query: `
      {
        seeMore: nodeQuery(
          filter: {
            conditions: [
              {field: "type", value: ["blog"]},
              {field: "status", value: ["1"]}
            ]
          }, 
          sort: [
            {
              field: "created"
              direction: DESC
            }
          ]
        ) {
          entities {
            ... on NodeBlog {
              id: nid,
              slug: fieldBlogSlug,
              title,
              content: fieldBlogContent {
                value
              },
              timestamp: created,
              srcImage: fieldBlogPostPhoto {
                derivative(style: BLOGSMALL) {
                  url,
                }
              },
            }
          }
        },
        slides: nodeQuery(
          filter: {
            conditions: [
              {field: "type", value: ["slide"]},
              {field: "status", value: ["1"]},
              {field: "field_slide_type", value: ["blog"]},
            ]
          },
        ) {
          entities {
            ... on NodeSlide {
              imageUrl: fieldSlideImage {
                derivative(style: MAINPAGESLIDER) {
                  url,
                }
              },
              title: fieldSlideTitle,
              description: fieldSlideDescription 
            }
          }
        }
      }
    `,
  });

  return ApiManager.request({
    dispatch,
    actions,
    requests,
  });
};
